.pnc-rewards-slider {
    width: 85%;
    margin: auto;
  }
  
  .pnc-rewards-slider-container {
    /* margin-top: 20px; */
  }
  
  .pnc-rewards-slider pnc-rewards-slider-card {
    width: 18rem;
  }
  
  .pnc-rewards-slider .card {
    border: none;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
  
  .pnc-rewards-slider .card-body {
    padding: 16px 16px 12px;
  }
  
  .pnc-rewards-slider .card-body:nth-of-type(2) {
    padding: 12px 14px 16px;
    min-height: 115px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  
  .pnc-rewards-slider h3 {
    color: #333f48;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-align: left;
    margin: 0;
    padding-bottom: 12px;
  }
  
  .pnc-rewards-slider .card-title {
    color: #333f48;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-align: left;
    margin: 0;
    padding-bottom: 12px;
  }
  
  .pnc-rewards-slider .card-text {
    text-align: left;
    margin: 0;
    color: #333f48;
    font-family: "PNC Sans Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }
  
  .pnc-rewards-slider-desc {
    min-height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .pnc-rewards-slider .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .pnc-rewards-slider .btn {
    background-color: #ef6a00;
    color: #ffffff;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    width: 100%;
    padding-block: 5.5px 7.5px;
    border: none;
  }
  
  .pnc-rewards-slider .btn.pnc-rewards-slider-btn:hover,
  .pnc-rewards-slider .btn.pnc-rewards-slider-btn:focus-visible,
  .pnc-rewards-slider .btn.pnc-rewards-slider-btn:focus,
  .pnc-rewards-slider .btn.pnc-rewards-slider-btn:not(:disabled):not(.disabled):active {
    color: #ffffff;
    background-color: #ca7531;
    border-color: #ca7531;
  }
  
  .pnc-rewards-slider .btn.pnc-rewards-slider-btn-2 {
    background-color: #617380;
    border-color: #617380;
    color: #ffffff;
    margin-top: 10px;
  }
  
  .pnc-rewards-slider .btn.pnc-rewards-slider-btn-2:hover,
  .pnc-rewards-slider .btn.pnc-rewards-slider-btn-2:focus-visible,
  .pnc-rewards-slider .pnc-rewards-slider-btn-2:focus,
  .pnc-rewards-slider .pnc-rewards-slider-btn-2:not(:disabled):not(.disabled):active {
    color: #ffffff;
    background-color: #48545d;
    border-color: #48545d;
  }
  
  .pnc-rewards-slider .btn:focus,
  .pnc-rewards-slider .btn:focus-visible {
    box-shadow: none;
    outline: 2px solid black;
  }
  
  .pnc-rewards-slider .slick-list {
    overflow: visible;
  }
  
  .pnc-rewards-slider .slick-slide > div {
    margin: 0 8px;
  }
  