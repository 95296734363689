.pnc-disclosure-title {
    background-color: #2d3943;
    display: flex;
    justify-content: space-around;
    color: #fff;
    padding: 24px 15px 24px 0px;
    border-left: none;
    border-right: none;
    font-family: "PNC Sans General";
    cursor: pointer;
}
.pnc-accordion-button {
    font-size: 24px;
}
.pnc-disclosure-content{
    background-color: #414e58;
    color: #e1e5ea;
    font-family: "PNC Sans Medium";
    font-size: 15px;
    display: flex;
    padding: 48px 62px;
    margin: 0 auto;
    justify-content: center;
}
.pnc-disc-texts {
    margin-top: 1.5em;
}
.pnc-disc-texts a{
    text-decoration: underline;
    color: #fff;
}
.pnc-disclosure-content-styles {
    max-width: 1185px;
}
.pnc-first-disclosure {
    display: flex;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 48px;
}
.pnc-first-disclosure a{
    color: #fff;
    text-decoration: underline;
}
@media(min-width: 768px){
    .pnc-disclosure-content-styles {
        max-width: 753px;
    }
    .pnc-disclosure-content {
        padding: 24px;
    }
}
@media(min-width: 992px){
    .pnc-disclosure-content-styles {
        max-width: 977px;
    }
}
@media(max-width: 768px){
    .pnc-first-disclosure {
        margin-left: 12px;
    }
    .pnc-disc-title {
        font-size: 21px;
    }
    .pnc-accordion-button {
        margin-right: 10px;
    }
    .pnc-disclosure-content {
        padding: 32px;
    }
    .pnc-disc-title {
        margin-left: 24px;
    }
}
.pnc-disc-first-p{
    max-width: 1045px;
}
.pnc-disc-number-one {
    margin-right: 1em;
}
.pnc-disc-img {
    width: auto;
    margin: 15px auto;
    max-width: 100%;
}