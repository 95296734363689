.pnc-redeem-rewards {
    background-color: #ffffff;
    color: #000000;
    overflow: hidden;
    padding-top: 3em;
    padding-bottom: 2.7em;
    padding-inline: 3rem;
    text-align: center;
    font-family: "PNC Sans General";
  }
  
  @media (max-width: 768px) {
    .pnc-redeem-rewards {
      padding-inline: 0;
      padding-top: 2em;
    }
  }
  