/* General Container */
#pnc-header {
  background-color: #414e58;
}
/* Navbar Styling */
#pnc-header .navbar {
  padding: 0;
}

#pnc-header .nav-link {
  color: #ffffff;
}
#pnc-header .navbar-toggler {
  padding: 0;
  margin-right: 24px;
}

#pnc-header .navbar-toggler-icon {
  background-image: url("../../../public/images/close-icon.svg");
  width: 25px;
  height: 25px;
}

#pnc-header .navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("../../../public/images/hamburger.svg");
}

#pnc-header .navbar-toggler:focus,
#pnc-header .navbar-toggler:active {
  outline: none;
  box-shadow: none;
  border: none;
}

/* Dropdown Styling */
#pnc-header .dropdown {
  font-weight: 400;
  font-size: 10.5pt;
  line-height: 15pt;
  color: #484848;
  font-family: "PNC Sans Light";
  list-style: none;
  position: relative;
  margin: 0;
  display: inline-block;
}

#pnc-header .dropdown-menu {
  font-weight: 400;
  line-height: 15pt;
  color: #484848;
  font-family: "PNC Sans Light";
  z-index: 1000;
  float: left;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  top: 96%;
  left: 25px;
  border-radius: 0;
  padding: 20px;
  border-bottom: 5px solid #f58025;
}

#pnc-header .dropdown-item {
  line-height: 1.5;
  text-decoration: none;
  width: 100%;
  background-color: transparent;
  border: 0;
  font-weight: bold;
  color: #2d3942;
  padding: 3px 0px 5px 0px;
  font-size: 14px;
  font-family: "PNC Sans Regular";
}

#pnc-header .dropdown-item:hover {
  background-color: transparent;
}

/* Top Navbar */
#pnc-header .pnc-top-navbar {
  background-color: #1b1b1b;
  padding: 3px 16px;
}

#pnc-header .pnc-nav-right {
  text-align: right;
}

#pnc-header .pnc-return-to-account {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  font-family: "PNC Sans Medium";
  position: relative;
}
#pnc-header .pnc-return-to-account::before {
  content: "";
  position: absolute;
  left: 5px;
  top: 14px;
  background-image: url("../../../public/images/arrow-left.svg");
  width: 5px;
  height: 11px;
  background-repeat: no-repeat;
  background-size: contain;
}
#pnc-header .pnc-return-to-account:hover {
  color: #fff;
  text-decoration: underline;
}
#pnc-header .pnc-navbar-logo {
  width: 210px;
  height: 55px;
}
#pnc-header .pnc-user-info {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  font-family: "PNC Sans Medium";
  text-align: right;
}

#pnc-header .pnc-user-name {
  color: #fff;
}

#pnc-header .pnc-user-points {
  font-size: 14px;
  color: #f96806;
  font-weight: bold;
  line-height: 1.5;
}

/* Main Navbar */
#pnc-header .pnc-main-navbar {
  background-color: #414e58;
}

#pnc-header .pnc-main-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

/* Nav Link Styling */
#pnc-header .pnc-nav-link,
#pnc-header .pnc-nav-dropdown {
  font-family: "PNC Sans Light";
  list-style: none;
  display: block;
  padding: 24px;
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
  text-decoration: none;
  background-color: transparent;
  position: relative;
  font-weight: bold;
}

#pnc-header .pnc-nav-link.active,
#pnc-header .pnc-nav-dropdown.active {
  background-color: #2d3943;
}

#pnc-header .pnc-nav-link.active:before,
#pnc-header .pnc-nav-dropdown.active:before {
  content: "";
  position: absolute;
  top: 0;
  border-top: 6px solid #f58025;
  width: 100%;
  left: 0;
}
#pnc-header .pnc-nav-dropdown .nav-link {
  padding: 0;
}
#pnc-header .pnc-sign-out.active:before {
  border: 0;
}
/* Up Arrow */
#pnc-header .pnc-up-arrow {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  position: absolute;
  left: 17px;
  top: -9px;
}

#pnc-header .pnc-sign-out {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  font-family: "PNC Sans Light";
  margin-left: auto;
}
#pnc-header .pnc-sign-out::after {
  content: "";
  background-image: url("../../../public/images/logout.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  margin-left: 5px;
}

/* Dropdown Hover */

#pnc-header .dropdown-toggle::after {
  content: none;
}

/* Show on Mobile */
#pnc-header .pnc-show-mobile {
  display: none;
}

/* Show on Desktop */
#pnc-header .pnc-show-desktop {
  display: block;
}

/* ------------------- MEDIA QUERIES ------------------- */
@media (min-width: 990px) {
  #pnc-header .navbar-expand-lg {
    flex-flow: column;
  }
  #pnc-header .pnc-logo-container {
    margin: 0 auto;
  }
}

@media (min-width: 1200px) {
  #pnc-header .container {
    max-width: 1160px;
  }
}

@media (max-width: 990px) {
  #pnc-header {
    background-color: transparent;
  }
  #pnc-header .pnc-logo-container {
    margin-right: auto;
  }
  #pnc-header .pnc-nav-link.active,
  #pnc-header .pnc-nav-dropdown.show {
    background-color: #f58025;
  }
  #pnc-header .pnc-return-to-account {
    font-size: 11px;
    line-height: 1.3;
    color: #333f48;
    padding: 12px 16px;
    margin-left: 16px;
  }
  #pnc-header .pnc-return-to-account:hover {
    color: #333f48;
  }

  #pnc-header .pnc-return-to-account::before {
    background-image: url("../../../public/images/arrow-left-black.svg");
  }

  #pnc-header .pnc-nav-dropdown .dropdown-item {
    font-size: 16px;
    line-height: 1.5;
  }
  #pnc-header .pnc-nav-link.active:before,
  #pnc-header .pnc-nav-dropdown.active:before {
    content: none;
  }
  #pnc-header .dropdown {
    width: 100%;
  }

  #pnc-header .dropdown-menu {
    position: relative;
    width: 100%;
    left: 0;
    border: 0;
    padding: 0;
  }

  #pnc-header .dropdown-item {
    padding: 0 50px;
  }

  #pnc-header .dropdown-item:last-child {
    padding-bottom: 12px;
    padding-top: 12px;
  }

  #pnc-header .dropdown-item:not(:first-child):not(:last-child) {
    padding-top: 12px;
  }

  #pnc-header .pnc-up-arrow {
    display: none;
  }

  #pnc-header .pnc-main-navbar.container {
    max-width: 100%;
    position: absolute;
    top: 71px;
    z-index: 2;
  }

  #pnc-header .pnc-nav-link,
  #pnc-header .pnc-nav-dropdown {
    width: 100%;
    border-bottom: 1px solid #2d3942;
  }
  #pnc-header .pnc-nav-dropdown {
    padding: 0;
  }
  #pnc-header .pnc-nav-link,
  #pnc-header .pnc-nav-dropdown .nav-link {
    padding: 11px 24px;
    display: flex;
    justify-content: space-between;
  }
  #pnc-header .pnc-sign-out {
    justify-content: start;
  }
  #pnc-header .dropdown-toggle::after {
    display: inline-block;
    font-family: "Glyphicons Halflings";
    content: url(../../../public/images/icon-expand.svg);
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: 0px;
  }

  #pnc-header .pnc-nav-dropdown.show .dropdown-toggle:after {
    display: inline-block;
    font-family: "Glyphicons Halflings";
    content: url(../../../public/images/icon-collapse.svg);
    color: #fff;
    border: 0px;
    font-size: 16px;
    font-weight: bold;
  }

  #pnc-header .pnc-show-mobile {
    display: block !important;
  }

  #pnc-header .pnc-show-desktop {
    display: none !important;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  #pnc-header .nav-link {
    padding: 15px 15px;
  }
  #pnc-header .pnc-return-to-account::before {
    top: 28px;
  }
}

@media (max-width: 413px) {
  #pnc-header .navbar-toggler {
    margin-right: 14px;
  }
  #pnc-header .pnc-navbar-logo {
    width: 160px;
    height: auto;
    max-height: 55px;
  }
}
