.order-detail-popup .modal-title {
    height: 28px;
    color: #FFFFFF;
    font-family: "PNC Sans Medium";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
  }
  .order-detail-popup  .form-group{
      margin-bottom:0px;
  }
  .modal-header{
        height: 75px;
        background-color: #F58025;
            display: flex;
            align-items: center;
            justify-content: center;
  }
  .modal-header .close, .modal-header .btn-close{
      display: none;
  }
  .modal-footer{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-content .modal-body {
    padding: 36px 20px 20px;
}
  .modal-footer .btn.btn-primary, .modal-footer .btn-primary:not(:disabled):not(.disabled):active{
    height: 44px;
    color: #FFFFFF;
    font-family: "PNC Sans Medium";
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
    border-radius: 4px;
    background-color: #F58025;
    border:0;
  }
  .modal-dialog{
      top:16%;
  }
  .modal-content{
      border:0;
  }
  .col-form-label{
    color: #2D3942;
    font-family: "PNC Sans Medium";
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
  }
  .form-control-plaintext,.form-control-plaintext p{
    color: #2D3942;
    font-family:'PNC Sans Regular';
    font-size: 18px;
    font-weight:100;
    letter-spacing: 0;
    line-height: 28px;
  }
  .modal-content .modal-body .form-control-plaintext p{
    margin-bottom: 0;
  }