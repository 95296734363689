.pnc-carousel .carousel {
    display: grid;
    position: relative;
  }
  .pnc-carousel .carousel-indicators {
    position: absolute;
    order: 1;
    gap: 5px;
  }
  .pnc-carousel .carousel-indicators button {
    width: 12px;
    height: 12px;
    border: 1px solid #333f48;
    border-radius: 50%;
    opacity: 1;
    margin-top: 1rem;
    background-color: #fff;
    outline: none;
    padding: 0;
  }
  .pnc-carousel .carousel-indicators .active {
    border: 1px solid #ef6a00;
    background-color: #ef6a00;
  }
  .pnc-carousel .carousel-caption {
    position: relative;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
  }
  .pnc-carousel .carousel-caption p:last-child {
    margin-bottom: 0;
  }
  .pnc-carousel-image {
    width: 100%;
    height: auto;
  }
  .pnc-carousel-title,
  .pnc-carousel-subtitle,
  .pnc-carousel-body {
    color: #333f48;
  }
  .pnc-carousel-title {
    font-size: 22px !important;
  }
  .pnc-carousel-subtitle {
    font-weight: 700;
    font-family: "PNC Sans General";
    font-size: 1.25rem !important;
    line-height: 1.288;
  }
  .pnc-carousel-body {
    font-family: "PNC Sans Regular";
    font-size: 1rem;
  }
  .pnc-carousel .carousel-caption p {
    float: left;
    width: 100%;
  }
  .pnc-carousel-cta {
    font-family: "PNC Sans General";
    font-size: 1rem;
    font-weight: 700;
    padding: 6px 24px 7px;
    background-color: #ef6a00;
    color: #fff;
    border-radius: 4px;
    text-decoration: none;
    float: left;
  }
  .pnc-carousel-cta:hover {
    color: #fff;
    background-color: #c95a00;
    text-decoration: none;
  }
  
  .pnc-carousel .carousel-control-prev,
  .pnc-carousel .carousel-control-next {
    width: 60px;
    z-index: 10;
    display: none;
  }
  .pnc-carousel .carousel-control-prev-icon,
  .pnc-carousel .carousel-control-next-icon {
    width: 28px;
    height: 28px;
  }
  .pnc-carousel .carousel-control-prev-icon {
    background-image: url("./../../../public/images/forward_arrow.png");
  }
  .pnc-carousel .carousel-control-next-icon {
    background-image: url("./../../../public/images/backward_arrow.png");
  }
  .visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  .pnc-carousel .carousel-item {
    transition: opacity 0.6s ease-in-out;
    opacity: 0;
  }
  /* .pnc-mb-50{
      margin-bottom: 50px;
  } */
  /* Responsive Style */
  @media (max-width: 991px) {
    .pnc-carousel .carousel-inner {
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
      margin-bottom: 30px;
    }
    .pnc-carousel .carousel-caption {
      padding: 20px;
    }
    .pnc-carousel-image {
      padding: 20px 20px 0;
      height: 189px;
      object-fit: cover;
    }
    .pnc-carousel-cta {
      width: 100%;
      text-align: center;
      padding: 6px 12px 7px;
      font-family: "PNC Sans Medium";
      font-weight: 500;
    }
  }
  @media (min-width: 992px) {
    .pnc-carousel .container {
      max-width: 1160px;
      padding: 0;
    }
    .pnc-carousel-title,
    .pnc-carousel-subtitle,
    .pnc-carousel-body {
      color: #fff;
    }
    .pnc-carousel .carousel-caption {
      width: 100%;
      height: 100%;
      right: 0;
      bottom: 0;
      left: 0;
      padding-left: 60px;
      padding-top: 70px;
      padding-bottom: 120px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;
      position: absolute;
    }
    .pnc-carousel-title,
    .pnc-carousel-subtitle {
      font-size: 2.25rem !important;
    }
    .pnc-carousel-subtitle {
    font-family: "PNC Sans Medium";
    font-weight: 500;
    }
    .pnc-carousel-body {
      font-size: 1.5rem !important;
      line-height: 1.2;
    }
    .pnc-carousel-cta {
      font-size: 1.25rem !important;
      line-height: 120%;
    }
    .pnc-carousel .carousel-indicators {
      bottom: -35px;
    }
    .pnc-carousel .carousel-indicators button {
      width: 15px;
      height: 15px;
    }
    .pnc-carousel .carousel-control-prev,
    .pnc-carousel .carousel-control-next {
      display: flex;
    }
    .pnc-mb-50 {
      margin-bottom: 50px;
    }
  }
  