.pnc-rewards-dashboard {
  width: 100%;
  max-width: 1200px; /* this can change to 1160 */
  margin: 0 auto;
  padding: 76px 20px 47px 20px; 
  font-family: "PNC Sans General";
  color: rgba(51, 63, 72, 1);
  gap: 32px;
  display: flex;
  justify-content: space-between;
}
.pnc-card-info {
  white-space: nowrap;
}
.pnc-rewards-dashboard .pnc-points-number {
  margin-bottom: 0.5rem;
}
.pnc-rewards-dashboard .pnc-card-image {
  width: 118px;
}
.pnc-rewards-dashboard .pnc-rewards-dashboard-titles {
  font-size: 14px;
  font-weight: 700;
}
.pnc-rewards-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.pnc-dashboard-content {
  gap: 24px;
}

.pnc-dashboard-content h5{
  font-family: "PNC Sans Medium";
}

.pnc-rewards-dashboard button {
  color: #fff;
  font-family: "PNC Sans General";
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  border-radius: 4px;
  background-color: rgba(239, 106, 0, 1);
  padding: 5.5px 20px 7.5px;
  border: none;
  width: fit-content;
  align-self: center;
  margin-top: 7px;
  margin-bottom: 7px;
}

.pnc-rewards-dashboard button:hover {
  color: #fff;
  background-color: #c95a00;
}

.pnc-rewards-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 12px; 
  /* justify-content: space-around; */
}

@media(min-width: 1190px){
  .pnc-rewards-flex {
    flex-wrap: nowrap; 
  }
  .pnc-rewards-dashboard {
    padding-inline: 1rem;
  }
}

.pnc-reward-box {
  flex: 1; 
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid rgba(237, 237, 238, 1);
  border-right: 1px solid rgba(237, 237, 238, 1);
  text-align: center;
  min-width: 195px;
  max-height: 137px;
  /* max-width: 198px;  */
}
.pnc-reward-info {
  padding-top: 4px;
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.pnc-reward-info p {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.pnc-reward-info h2, .pnc-reward-info h3 {
  margin: 0;
  font-weight: 700;
}

.pnc-reward-info h6 {
  font-weight: 400;
  font-family: "PNC Sans Regular";
}

.pnc-rewards-dashboard-titles {
  font-size: 14px;
  margin-bottom: 8px;
  white-space: nowrap;
  padding: 0px 20px;
}

.pnc-rewards-balance-text {
  gap: 8px;
  justify-content: center;
  padding: 0px 20px;
}

.pnc-orange-font {
  color: rgba(239, 106, 0, 1);
}

.pnc-points-earned-box {
  display: block;
}

.pnc-grey-reward {
  background-color: #fff;
  white-space: nowrap;
  margin-top: 10px;
  width: 100%; 
}

.pnc-grey-reward .pnc-orange-font{
  font-weight: 700;
  font-size: 20px;
  font-family: "PNC Sans General";
}

.pnc-rewards-header h2 {
  margin-bottom: 1.5rem;
  font-weight: 700;
}
@media(min-width: 769px) and (max-width: 1198px){
  .pnc-rewards-dashboard {
    padding-inline: 3rem;
  }
  .pnc-reward-info h2, .pnc-reward-info h3 {
    font-size: 1.8rem;
  }
}
@media (max-width: 768px) {
  .pnc-rewards-dashboard {
    max-width: 460px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 16px;
    padding: 42px 15px 30px 15px; 
  }
  .pnc-card-info {
    white-space: wrap;
    text-align: center;
  }  
  .pnc-reward-info h2, .pnc-reward-info h3 {
    font-size: 1.4rem;
  }
  .pnc-rewards-header {
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
  }
  .pnc-rewards-header h2 {
    margin-bottom: 1rem;
  }
  .pnc-reward-box {
    background-color: #fff;
  }
  .pnc-dashboard-content {
    align-items: center;
    flex-direction: column-reverse;
    gap: 0px;
  }
  .pnc-reward-info p{
    font-size: 14px;
  }
  .pnc-rewards-dashboard .pnc-points-number {
    margin-bottom: 0px;
  }
}
@media (max-width: 768px) and (min-width: 365px) {
  .pnc-rewards-flex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
  .pnc-rewards-dashboard {
    padding-left: 15px;
    padding-right: 15px;
  }
  .pnc-one-single-card {
    grid-template-columns: 1fr; 
    width: 100%;
  }
  .pnc-one-single-card .pnc-reward-box {
    min-width: 100%;
    max-width: 100%;
  }

  .pnc-reward-box {
    min-width: 0;
  }
  .pnc-three-cards {
    grid-template-columns: repeat(2, 1fr); 
  }

  .pnc-three-cards .pnc-reward-box:nth-child(1),
  .pnc-three-cards .pnc-reward-box:nth-child(2) {
    grid-column: span 1; 
  }

  .pnc-three-cards .pnc-reward-box:nth-child(3) {
    grid-column: 1 / -1; 
    min-width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 420px) {
  .pnc-rewards-flex {
    display: grid;
    grid-template-columns: 1fr; 
    gap: 12px;
    width: 100%;
  }
  .pnc-rewards-dashboard {
    padding-left: 15px;
    padding-right: 15px;
  }
  .pnc-reward-box {
    min-width: 100%;
    max-width: 100%;
  }
}