.pnc-additional-benefits-section {
  background: #ffffff;
  padding-inline: 27px;
  padding-top: 30px;
  padding-bottom: 60px;
}

.pnc-additional-benefits-section .pnc-additional-benefits-title {
  color: rgba(51, 63, 72, 1);
  text-align: left;
  margin: 0;
  font-family: "PNC Sans General";

  padding-bottom: 10px;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.pnc-additional-benefits-section .pnc-additional-benefits-subtitle-row {
  padding: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  min-height: 80px;
  column-gap: 25px;
}

.pnc-additional-benefits-section .col {
  padding: 0;
}

.pnc-additional-benefits-section .pnc-additional-benefits-subtitle {
  align-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: #ef6a00;
  font-family: "PNC Sans General";
}

.pnc-additional-benefits-section .pnc-additional-benefits-subtitle a {
  color: #ef6a00;
}

.pnc-additional-benefits-section .pnc-additional-benefits-desc p {
  margin: 0;
}

.pnc-additional-benefits-section .pnc-additional-benefits-desc {
  align-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #333f48;
  flex-grow: 3;
  font-family: "PNC Sans Regular";
}

/* Reponsive */
@media (max-width: 768px) {
  .pnc-additional-benefits-section {
    padding-block: 32px;
    padding-inline: 27px;
  }

  .pnc-additional-benefits-section .pnc-additional-benefits-subtitle {
    padding-top: 4px;
    padding-bottom: 12px;
  }

  .pnc-additional-benefits-section .row {
    padding: 12px 0px;
  }

  .pnc-additional-benefits-section .row:last-child {
    border-bottom: none;
  }

  .pnc-additional-benefits-section .col {
    flex-basis: auto;
  }
}

@media (min-width: 1200px) {
  .pnc-additional-benefits-section.container {
    max-width: 1023px;
  }
}
