.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 300px;
  max-width: 90%;
  color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  text-align: center;
  z-index: 9999;
  opacity: 0;
  animation: 0.5s ease 0s 1 normal none running fadein, 0.5s ease 0.5s 1 normal none running expand,
    1e11s ease 1s 1 normal none running stay;
  background: #414e58;
  border-radius: 24px;
  white-space: nowrap;
}

.toast.show {
  opacity: 1;
}

.toast.hide {
  opacity: 0;
}

.toast-body {
  font-size: 16px;
  font-weight: normal;
  animation: scaleText 0.5s ease-in-out;
}

/* Keyframes for the fade-in effect */
@keyframes fadeIn {
  0% {
    opacity: 0;
    bottom: 10px;
  }
  100% {
    opacity: 1;
    bottom: 20px;
  }
}

/* Keyframes for expanding text from center */
@keyframes scaleText {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

/* Responsive Toast Size */
@media (max-width: 768px) {
  .toast {
    min-width: 260px;
    bottom: 15px;
    padding: 8px;
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .toast {
    min-width: 220px;
    bottom: 10px;
    padding: 6px;
    font-size: 13px;
  }
}
