.pnc-rewards-benefits {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  color: #000;
  font-family: "PNC Sans Regular";
}
.pnc-rewards-benefits .btn {
  font-family: "PNC Sans General";
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
  padding-block: 5.5px 7.5px;
}
.pnc-rewards-benefits .btn:focus{
  box-shadow: none; /*0 0 0 0.2rem rgba(0, 0, 0, 1);*/
}
.pnc-rewards-benefits .btn:focus-visible{
  border-radius: calc(0.25rem - 1px);
  outline: 2px solid black;
}
.pnc-rewards-benefits .container {
  max-width: 1140px;
}
.pnc-rewards-benefits .card {
  border: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.pnc-rewards-benefits .card-body {
  padding: 1rem;
  gap: 1rem;
}
.pnc-rewards-benefits .card-title {
  font-size: 1.1rem;
  color: #333f48;
  line-height: 1.5rem;
  font-weight: 700;
  font-family: "PNC Sans General";
}
.pnc-rewards-benefits .card-title span {
  color: #ef6a00;
  font-size: 1.5rem;
}
.pnc-rewards-benefits .card-img-top {
  padding: 1rem 1rem 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  height: 187px;
  object-fit: cover;
  object-position: top;
}

.pnc-rewards-benefits .card-text {
  font-size: 14px;
}

.pnc-rewards-benefits img {
  min-height: 171px;
  object-fit: cover;
}

.pnc-rewards-benefits p {
  font-size: 22px;
  margin-bottom: 0.5rem;
}

.pnc-rewards-benefits #pnc-rewards-benefits-section-heading {
  font-size: 36px;
  font-weight: bold;
  font-family: "PNC Sans General";
  color: #333f48;
}
.pnc-rewards-summary-btn {
  background-color: #EF6A00;
  border: none;
}
.pnc-rewards-benefits .btn-primary:hover,
.pnc-rewards-benefits .btn-primary:focus-visible  {
    background-color: #c95a00;
    border-color: #c95a00;
}

/* Flexbox Container for Cards */
.pnc-rewards-benefits .cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  max-width: 1160px;
  margin-top: 5px;
}

/* Wrapper for each card */
.pnc-rewards-benefits .card-wrapper {
  flex: 0 1 calc(33.333% - 20px); /* 3 cards per row, with 20px gap */
  display: flex;
  justify-content: center;
  min-height: 354px;
  margin-bottom: 20px;
}

/* Responsive behavior */
@media (max-width: 992px) {
  .pnc-rewards-benefits .card-wrapper {
    flex: 0 1 calc(50% - 20px); /* 2 cards per row for medium screens */
  }
}

@media (max-width: 768px) {
  .pnc-rewards-benefits .card-wrapper {
    flex: 1 1 calc(100% - 20px); /* 1 card per row for small screens */
  }
  .pnc-rewards-benefits .card {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .pnc-rewards-benefits {
    padding-top: 3.5rem;
    padding-bottom: 2.6rem;
  }
  .pnc-rewards-benefits .container {
    max-width: 1160px;
    padding: 0;
  }
}
@media (max-width: 992px) {
  .pnc-rewards-benefits .card {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .pnc-rewards-benefits {
    padding-top: 0;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 2rem;
  }
  .pnc-rewards-benefits .container {
    padding-left: 0;
    padding-right: 0;
    max-width: 540px;
  }
  .pnc-rewards-benefits #pnc-rewards-benefits-section-heading {
    line-height: 1.2;
  }
  .pnc-rewards-benefits .card-wrapper {
    margin-bottom: 0px;
  }
  .pnc-rewards-benefits img {
    max-height: none;
  }
  .pnc-rewards-benefits .card-img-top {
    padding: 16px 16px 0px 16px;
    height: 186px;
  }
  .pnc-rewards-benefits p {
    margin-bottom: 1rem;
  }
  .pnc-rewards-benefits .card-body {
    padding: 16px 12px 20px 12px;
  }
    
  .pnc-rewards-benefits .cards-container {
    margin-top: 5px;
  }
}
@media (max-width: 420px) {
  .pnc-rewards-benefits img {
    max-height: 186px;
    min-height: auto;
    object-position: top;
  }
}

